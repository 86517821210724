import "../styles/login.scss"
import React, { useState, useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import { Helmet } from "react-helmet"
import Header from "../components/Header"
// import Header from "../components/BlueHeader"
// import Header from "../components/TealHeader"
import ReverseAuthGate from "../components/Auth/ReverseAuthGate"
// import Footer from "../components/Footer"
// import favicon from "../images/favicon.ico"
import favicon from "../images/favicon.png"

import { navigate, graphql, Link } from "gatsby"
// import { useFirebaseArsenal } from "../../firebase/firebaseCentral"
// import { useAuth } from "../lib/firebase/firebaseCentral"
// import Img from "gatsby-image"
import toast from "react-hot-toast"
// import se from "react-netlify-identity";
import GoTrue from "gotrue-js"
import { StaticImage } from "gatsby-plugin-image"

export default function LoginForm() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [disable, setDisable] = useState(true)
  const [identity, setIdentity] = useState(null)
  const emailRef = useRef(null)

  // console.log("se", se);
  // console.log("isLoggedIn", isLoggedIn);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm()
  const { ref, ...rest } = register("email", {
    required: true,
    minLength: 3,
    maxLength: 20,
    pattern: {
      value: /\S+@\S+.\S+/,
      message: "Entered value does not match email format",
    },
  })

  useEffect(() => {
    const auth = new GoTrue({
      APIUrl: "https://www.berserkers.dev/.netlify/identity",
      audience: "",
      // setCookie(optional): set to be false by default. If you wish to implement the remember me functionality, set the value to be true.
      setCookie: false,
    })

    setIdentity(auth)
  }, [])

  const watchEmail = watch("email", "")
  const watchPassword = watch("password", "")

  // useEffect(() => {
  //   document.documentElement.style.setProperty(
  //     "--scrollbar-width",
  //     window.innerWidth - document.documentElement.clientWidth + "px"
  //   )
  // }, [])

  useEffect(() => {
    if (emailRef.current) emailRef.current.focus()
  }, [emailRef])

  // * -------------------------------------------- *
  // console.log("useAuth", useAuth())
  // const { signinWithEmail, sendPasswordResetEmail, signout } = useAuth()
  // console.log("signinWithEmail", signinWithEmail)
  // console.log("signout", signout)

  useEffect(() => {
    if (identity?.currentUser) {
      // console.log("identity.currentUser()", identity.currentUser());
    }
  }, [identity])

  const onSubmit = async data => {
    if (identity?.login) {
      // auth.signup(email, password);
      console.log("data", [data.email, data.password])
      identity
        .login(data.email, data.password, true)
        .then(response => {
          // console.log("Logged in | response", response);
          toast.success(`You are logged in!`)
          navigate("/dashboard")
        })
        .catch(error => {
          console.log("Logged in | error", error)
          toast.error(`Unable to login.`)
        })
    } else {
      toast.error(`Please refresh the page and try again.`)
    }
    // if (identity?.signup) {
    //   // auth.signup(email, password);
    //   identity
    //     .signup("madleo.dk@gmail.com", "123456789")
    //     .then((response) => console.log("Confirmation email sent", response))
    //     .catch((error) => console.log("It's an error", error));
    // }

    // console.log("getValues", getValues())
    // trySignIn(data.email);
    // try {
    //   trySignIn(data.email);
    // } catch (error) {
    //     console.log('error',error)
    // }
    // const res = signinWithEmail({ email: data.email, password: data.password })
    // console.log("res", res)
    // navigate("/dashboard");
  }

  const handleResetPassword = async () => {
    navigate("/passwordRecovery")
    // console.log("emailRef", emailRef)
    // sendPasswordResetEmail()
    //     identity
    //   .requestPasswordRecovery()
    //   .then((response) => console.log('Recovery email sent', { response }))
    //   .catch((error) => console.log('Error sending recovery mail: %o', error));

    //     toast.success("Your reset password email has been sent!");
  }

  // console.log("errors", errors)
  // console.log(watch("email")) // watch input value by passing the name of it
  // console.log(watch("password")) // watch input value by passing the name of it

  return (
    <>
      {/* <ReverseAuthGate> */}
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={favicon} />

        <title>Login</title>
      </Helmet>
      <div className="login-wrapper">
        {/* <div className="login-inner-wrapper"> */}

        <>
          <div className="login-img-left">
            {/* <Img fluid={data.two.childImageSharp.fluid} /> */}
            <StaticImage src="../images/abstract_5.png" />
          </div>
          <div className="login-img-right">
            <StaticImage src="../images/abstract_4.png" />
            {/* <Img fluid={data.one.childImageSharp.fluid} /> */}
          </div>
        </>

        <form className="login" onSubmit={handleSubmit(onSubmit)}>
          <div className="login__header">
            <div>Welcome Back</div>
          </div>
          <div className="login__container">
            <label className="login__label" htmlFor="email">
              Email
            </label>
            <input
              className="login__input"
              id="email"
              type="email"
              // placeholder="Enter your email"
              {...register("email", {
                required: { value: true, message: "email is required" },
                minLength: { value: 5, message: "Min chars is 5" },
                maxLength: {
                  value: 300,
                  message: "Max length is 300 characters",
                },
                pattern: {
                  value: /\S+@\S+.\S+/,
                  message: "Entered value does not match email format",
                },
              })}
            />
            {errors.email && <span>{errors.email.message}</span>}

            <label className="login__label" htmlFor="password">
              Password
            </label>
            <input
              className="login__input"
              id="password"
              type="password"
              // placeholder="Enter your password"
              {...register("password", {
                required: { value: true, message: "password is required" },
                minLength: { value: 9, message: "Min chars is 9" },
                maxLength: {
                  value: 25,
                  message: "Max length is 25 characters",
                },
              })}
            />
            {errors.password && <span>{errors.password.message}</span>}

            <button
              type="submit"
              className="login__confirm"
              // onClick={() => setStep("two")}
            >
              Login
            </button>
            <Link to="/passwordRecovery" className="login__reset">
              Forgot your password?
            </Link>
          </div>
        </form>
      </div>
      {/* </div> */}
      {/* <Footer /> */}
      {/* </ReverseAuthGate> */}
    </>
  )
}

// const Image = ({ img }) => {
//   const data = useStaticQuery(graphql`
//     query {
//       one: file(relativePath: { eq: "abstract_1.png" }) {
//         childImageSharp {
//           fluid(maxWidth: 300) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//       two: file(relativePath: { eq: "abstract_2.png" }) {
//         childImageSharp {
//           fluid(maxWidth: 300) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   `)

//   if (!data?.one?.childImageSharp?.fluid) {
//     return <div>Picture not found</div>
//   }

//   if (img === "one") {
//     return <Img fluid={data.one.childImageSharp.fluid} />
//   } else {
//     return <Img fluid={data.two.childImageSharp.fluid} />
//   }
// }
